import React from 'react';
import './Footer.css';

const Footer = () =>{
	return(
     <div className = "footer">
     	<p>Copyright © 2024. All rights are reserved</p>
     </div>
		);
}
export default Footer;