import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { AiOutlineMail } from "react-icons/ai";
import { FaPhoneAlt } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import './Contact.css'

const Contact = ()=>{

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_730dnep', 'template_skp29ch', form.current, '-petl82IOswMqoA0v')
      .then(
        () => {
          console.log('SUCCESS!');
          alert('email has been sent');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

	return(
  <div className="main-contact-container">
    <div className="contacts">
          <div className = "title">
          <h2>Contact</h2>
           <h1>Don't be shy! Hit me up!</h1>
          </div>
              <div className="list-container">
                   <div className = "contact">
                  <section className = "contact-container">
                    <h1 className = "contact-logo" href="https://mail.google.com/" target="_blank"><AiOutlineMail /></h1>
                    <div className = "contact-text">
                    <a href="mailto: wamikiya@gmail.com">wamikiya@gmail.com</a>
                    </div>
                    
                  </section>
                  <section className = "contact-container">
                    <a className = "contact-logo" href="https://github.com/wamisha654" target="_blank"><FaGithub /></a>
                    <div className = "contact-text">
                     <a href="https://github.com/wamisha654" target="_blank">wamisha654</a>
                    
                    </div>
                    
                  </section>
                  <section className = "contact-container">
                    <h1 className = "contact-logo"><FaPhoneAlt /></h1>
                    <div className = "contact-text">
                    <a href="tel: +48729246430">+48 729246430</a>
                    </div>
                    
                  </section>
                  <section className = "contact-container">
                    <a className = "contact-logo" href="https://www.linkedin.com/in/wamisha-minamo-27a3351a3/" target="_blank"><FaLinkedin /></a>
                    <div className = "contact-text">
                     <a href="https://www.linkedin.com/in/wamisha-minamo-27a3351a3/" target="_blank">Wamisha Minamo</a>
                    </div>
                    
                  </section>
                  
                </div>
                <form ref={form} onSubmit={sendEmail} className = "contact-form">
                      <div className="input-styler">
                        <div className="input-container">
                          <label>Name</label>
                          <input type="text" name="user_name" />
                        </div>
                        
                        <div className="input-container">
                           <label>Email</label>
                           <input type="email" name="user_email" />  
                        </div>
                      </div>
                        
                        
                        <div className="input-container">
                            <label>Message</label>
                            <textarea name="message" />
                        </div>
                        
                        <div>
                             <input type="submit" className="contact-button" value="Send" />
                        </div>
                  
                </form>
              </div>

    </div>   
  </div>  
  
		);
}

export default Contact;